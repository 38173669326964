import React, { FC } from 'react';

import {
  AvatarFallback,
  AvatarImage,
  Avatar as ShadcnAvatar,
} from '@/components/ui/avatar';
import { cn } from '@/lib/utils';

import AILogo from '@public/assets/images/ai-agent.png';

type AvatarProps = {
  className?: string;
  defaultAIAvatar?: boolean;
  src?: string;
  email?: string;
  size?: number;
  renderOnlineBadge?: boolean;
  onlineStatus?: boolean;
};

const Avatar: FC<AvatarProps> = ({
  className,
  src,
  email,
  size = 32,
  renderOnlineBadge = false,
  defaultAIAvatar = false,
  onlineStatus = false,
}) => {
  const _src = defaultAIAvatar ? src || AILogo.src : src;
  const sizeStyles = {
    width: `${size}px`,
    height: `${size}px`,
    minWidth: `${size}px`,
    minHeight: `${size}px`,
  };

  return (
    <div
      className="relative inline-flex"
      style={sizeStyles}
      data-testid={_src ? 'image-avatar-container' : 'default-avatar'}
    >
      <ShadcnAvatar className={cn('h-full w-full bg-gray-200', className)}>
        {_src ? (
          <AvatarImage
            data-testid="image-avatar"
            src={_src}
            alt={email || 'Avatar'}
            className={cn({ 'rounded-none': defaultAIAvatar })}
          />
        ) : (
          <AvatarFallback
            data-testid="default-avatar-text"
            className="bg-gray-200"
          >
            {email?.charAt(0) || '?'}
          </AvatarFallback>
        )}
      </ShadcnAvatar>

      {renderOnlineBadge && (
        <span
          className={cn(
            'absolute right-0 top-0 h-[6px] w-[6px] rounded-full ring-2 ring-white',
            {
              'bg-green-500': onlineStatus,
              'bg-red-500': !onlineStatus,
            },
          )}
        />
      )}
    </div>
  );
};

export default Avatar;
