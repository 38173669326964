'use client';

import { FC, useState } from 'react';

import ChevronRightIcon from '@heroicons/react/20/solid/ChevronRightIcon';
import { UserGroupIcon } from '@heroicons/react/24/outline';
import cn from 'classnames';

import useTranslationStore from '@/store/translation';

import AudienceBadge from './audience-badge.component';
import { MessageBoxStyle, MessegeBoxVariant } from './message-box.component';
import type { InboxPublicLink, PublicLinks } from './types';

type AudienceLogProps = {
  className?: string;
  position: 'left' | 'right';
  source?: PublicLinks;
  style: MessageBoxStyle;
  variant: MessegeBoxVariant;
};

const AudienceLog: FC<AudienceLogProps> = ({
  className,
  position,
  source,
  style,
  variant,
}) => {
  const [showAudienceLogs, setShowAudienceLogs] = useState<boolean>(false);
  const { getValue } = useTranslationStore();

  if (!source || !source.length) return null;

  // Filter items that have audience logs
  const itemsWithAudienceLog = source.filter(
    (item) =>
      'audienceLog' in item && item.audienceLog && item.audienceLog.length > 0,
  ) as InboxPublicLink[];

  if (itemsWithAudienceLog.length === 0) return null;

  const audienceLogsLabel = 'Audience Logs';

  if (variant === 'minimal') {
    return (
      <div
        className={cn(
          'grid grid-cols-[16px_1fr] gap-1.5 px-[18px] py-3',
          className,
        )}
        style={{
          color: style.textColor,
          borderTop: `1px solid ${style.borderColor}`,
        }}
      >
        <UserGroupIcon
          className={cn('h-4 w-4', {
            ['opacity-70']: !showAudienceLogs,
          })}
        />
        <div className="w-full">
          <button
            className={cn(
              'flex w-full cursor-pointer items-center justify-between text-xs font-medium',
              {
                ['opacity-70']: !showAudienceLogs,
              },
            )}
            onClick={() => setShowAudienceLogs(!showAudienceLogs)}
          >
            {audienceLogsLabel}
            <ChevronRightIcon
              className={cn('mr-0.5 h-4 w-4 transition-transform', {
                ['-rotate-90']: showAudienceLogs,
                ['rotate-90']: !showAudienceLogs,
              })}
              aria-hidden="true"
            />
          </button>
          {showAudienceLogs && (
            <div className="mt-2 flex flex-wrap items-start justify-start gap-1">
              {itemsWithAudienceLog.map((item, index) => (
                <div
                  key={index}
                  className="flex flex-wrap items-start justify-start gap-1"
                  onClick={(e) => e.stopPropagation()}
                >
                  {item.audienceLog.map((audience) => (
                    <AudienceBadge
                      key={audience.id}
                      audienceName={audience.audienceName}
                      audienceLogId={audience.id}
                    />
                  ))}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className={cn(className)}>
      <button
        className={cn(
          'flex cursor-pointer items-center text-xs font-medium text-gray-500',
          {
            ['mr-auto']: position === 'left',
            ['ml-auto flex-row-reverse']: position === 'right',
          },
        )}
        onClick={() => setShowAudienceLogs(!showAudienceLogs)}
      >
        {audienceLogsLabel}
        <ChevronRightIcon
          className={cn('mr-0.5 h-4 w-4 transition-transform', {
            ['rotate-90']: showAudienceLogs,
            ['rotate-0']: !showAudienceLogs && position === 'left',
            ['rotate-180']: !showAudienceLogs && position === 'right',
          })}
          aria-hidden="true"
        />
      </button>
      {showAudienceLogs && (
        <div
          className={cn('mt-2 flex flex-wrap items-center gap-2', {
            ['justify-end']: position === 'right',
            ['justify-start']: position === 'left',
          })}
        >
          {itemsWithAudienceLog.map((item, index) => (
            <div
              key={index}
              className="flex flex-wrap items-start justify-start gap-1"
              onClick={(e) => e.stopPropagation()}
            >
              {item.audienceLog.map((audience) => (
                <AudienceBadge
                  key={audience.id}
                  audienceName={audience.audienceName}
                  audienceLogId={audience.id}
                />
              ))}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default AudienceLog;
