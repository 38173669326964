'use client';

import { FC } from 'react';

import { xcodeLight } from '@uiw/codemirror-theme-xcode';
import CodeMirror from '@uiw/react-codemirror';
import cn from 'classnames';

import { useGetChatbotAudienceLog } from '@/hooks/useGetChatbotAudienceLog';

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '../ui/dialog';

type AudienceBadgeProps = {
  className?: string;
  audienceName: string;
  audienceLogId: number;
};

const AudienceBadge: FC<AudienceBadgeProps> = ({
  className,
  audienceName,
  audienceLogId,
}) => {
  const { data: audienceLog, isLoading } = useGetChatbotAudienceLog({
    audienceLogId: Number(audienceLogId),
  });

  if (!audienceName) return null;
  if (!audienceLogId) return null;

  return (
    <Dialog>
      <DialogTrigger
        className={cn(
          'inline-flex items-center rounded-md bg-blue-100 px-1.5 py-1 text-xs text-blue-600 ring-1 ring-inset ring-blue-700/10',
          className,
        )}
      >
        {audienceName}
      </DialogTrigger>
      <DialogContent className="sm:max-w-[520px]">
        <DialogHeader>
          <DialogTitle>Audience Usage Log</DialogTitle>
          <DialogDescription>
            This audience has been used in the following rules and contact
            attributes
          </DialogDescription>
        </DialogHeader>

        {isLoading ? (
          <div>Loading...</div>
        ) : (
          <div>
            <div className="mt-2">
              <span className="inline-flex items-center rounded-md bg-blue-100 px-1.5 py-1 text-blue-600 ring-1 ring-inset ring-blue-700/10">
                {audienceLog?.audienceName}
              </span>
            </div>

            <p className="mb-2 mt-4 text-sm font-medium">Audience Rule:</p>
            <CodeMirror
              className="w-full overflow-hidden rounded-xl border border-gray-200"
              value={JSON.stringify(audienceLog?.audienceQueryLog, null, 2)}
              height="180px"
              theme={xcodeLight}
              basicSetup={{
                autocompletion: true,
                bracketMatching: true,
                closeBrackets: true,
                highlightActiveLine: false,
              }}
              readOnly={true}
            />

            <div className="mt-4">
              <p className="mb-2 text-sm font-medium">Contact Attributes</p>
              {audienceLog?.contactAttributeLog &&
              Array.isArray(audienceLog?.contactAttributeLog) ? (
                <div className="max-h-[180px] w-full overflow-auto rounded-xl border border-gray-200">
                  {audienceLog?.contactAttributeLog.map(
                    (attribute: { key: string; value: string }) => {
                      if (!attribute?.key || attribute?.key?.trim() === '')
                        return null;
                      if (!attribute?.value || attribute?.value?.trim() === '')
                        return null;

                      return (
                        <div
                          key={attribute.key}
                          className="hover:bg-muted/50 grid grid-cols-2 items-center border-b border-gray-200 px-3"
                        >
                          <span className="text-muted-foreground border-r border-gray-200 py-2 text-sm font-normal">
                            {attribute.key}
                          </span>
                          <span className="text-muted-foreground py-3 pl-2 text-sm font-normal">
                            {attribute.value}
                          </span>
                        </div>
                      );
                    },
                  )}
                </div>
              ) : (
                <CodeMirror
                  className="w-full overflow-hidden rounded-xl border border-gray-200"
                  value={JSON.stringify(
                    audienceLog?.contactAttributeLog,
                    null,
                    2,
                  )}
                  height="180px"
                  theme={xcodeLight}
                  basicSetup={{
                    autocompletion: true,
                    bracketMatching: true,
                    closeBrackets: true,
                    highlightActiveLine: false,
                  }}
                  readOnly={true}
                />
              )}
            </div>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default AudienceBadge;
