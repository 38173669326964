'use client';

import React, { FC, memo } from 'react';

import { MessageType } from '@prisma/client';
import cn from 'classnames';
import Linkify from 'linkify-react';
import ReactMarkdown, { Components } from 'react-markdown';
import remarkGfm from 'remark-gfm';

import MarkdownImage from './markdown-image.component';

export type MessageMarkdownProps = {
  className?: string;
  children: string;
  type: MessageType;
  aiTyping?: boolean;
};

const MessageMarkdown: FC<MessageMarkdownProps> = ({
  className,
  children,
  type,
  aiTyping = false,
}) => {
  // Custom rehype plugin to add a span to the last element
  const addSpanToLastElement = () => {
    return (tree: any) => {
      const lastElement = tree.children[tree.children.length - 1];
      if (
        lastElement &&
        lastElement.type === 'element' &&
        !['img', 'br', 'hr', 'input', 'meta', 'link'].includes(
          lastElement.tagName,
        )
      ) {
        lastElement.children.push({
          type: 'element',
          tagName: 'span',
          properties: { className: 'message-last-element-character' },
          children: [{ type: 'text', value: '' }],
        });
      }
    };
  };

  return (
    <>
      <ReactMarkdown
        className={cn(
          'prose w-full overflow-auto break-words leading-[inherit] text-inherit',
          className,
        )}
        components={
          {
            p: (props) => {
              // Check if the paragraph contains only an image
              const hasOnlyImage = React.Children.toArray(props.children).every(
                (child) => React.isValidElement(child) && child.type === 'img',
              );

              if (hasOnlyImage) {
                return <>{props.children}</>;
              }

              return (
                <Linkify
                  options={{
                    render: ({ attributes, content }) => {
                      return (
                        <a
                          {...attributes}
                          target="_blank"
                          rel="nofollow noopener noreferrer"
                        >
                          {content}
                        </a>
                      );
                    },
                  }}
                >
                  <p>{props.children}</p>
                </Linkify>
              );
            },
            a: (props) => {
              if (props.href.startsWith('#'))
                return <span>{props.children}</span>;

              return (
                <a
                  href={props.href}
                  target="_blank"
                  rel="nofollow noopener noreferrer"
                >
                  {props.children}
                </a>
              );
            },
            img: (props) => <MarkdownImage src={props.src || ''} />,
            table: (props) => {
              return (
                <div className="relative mt-4 w-full overflow-x-auto pb-3">
                  <table className="!my-0 min-w-full table-auto">
                    {props?.children}
                  </table>
                </div>
              );
            },
            tr: (props) => {
              return <tr className="whitespace-nowrap">{props.children}</tr>;
            },
            th: (props) => {
              return (
                <th className="px-2 py-1.5 text-left">{props.children}</th>
              );
            },
            td: (props) => {
              return (
                <td className="px-2 py-1.5 text-left">{props.children}</td>
              );
            },
          } as Partial<Components>
        }
        remarkPlugins={[remarkGfm]}
        remarkRehypeOptions={{
          footnoteBackContent: (
            referenceIndex: number,
            rereferenceIndex: number,
          ) => {
            return {
              type: 'element',
              tagName: 'sup',
              properties: {},
              children: [{ type: 'text', value: String(rereferenceIndex) }],
            };
          },
        }}
        rehypePlugins={aiTyping ? [addSpanToLastElement] : undefined}
      >
        {type === MessageType.TEXT ? children : `![Image message](${children})`}
      </ReactMarkdown>
    </>
  );
};

export default memo(MessageMarkdown);
