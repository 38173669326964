import { useQuery } from '@tanstack/react-query';

import { getChatbotAudienceLog } from '@/app/actions/getChatbotAudienceLog';

type GetChatbotAudienceLogParams = {
  audienceLogId: number;
};

export const getUseChatbotAudienceLogKey = (audienceLogId: number): string => {
  return `chatbot-audience-log-${audienceLogId}`;
};

export function useGetChatbotAudienceLog({
  audienceLogId,
}: GetChatbotAudienceLogParams) {
  const key = getUseChatbotAudienceLogKey(audienceLogId);

  return useQuery({
    queryKey: [key],
    enabled: !!audienceLogId,
    queryFn: () => getChatbotAudienceLog(audienceLogId),
  });
}
